<template>
  <div
    class="app-wrapper"
  >
    <div
      v-if="isAuthCheckRequested && isAutoAuthing"
      class="auto-login-loader"
    >
      <v-progress-circular
        :size="70"
        color="#055289"
        indeterminate
      />
    </div>

    <Layout />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import Layout from './layout/views/Layout.vue'

const { mapState } = createNamespacedHelpers('auth')

export default {
  name: 'App',
  components: { Layout },
  computed: {
    ...mapState({
      isAutoAuthing: (state) => state.isAutoAuthing,
      isAuthCheckRequested: (state) => state.isAuthCheckRequested,
    }),
  },
}
</script>

<style lang="scss">
@import "./src/styles/app";

#app {
  font-family: "Montserrat", sans-serif;
}

.app-wrapper {
  height: 100%;
}

.auto-login-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
</style>
